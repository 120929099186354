import React, { forwardRef, useEffect, useState } from 'react'

const ImageLazy = forwardRef((props, ref) => {
  const { alt, imgURL, customClass } = props
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    // Viewport observer for image
    const viewportImageObserver = (target) => {
      const options = {
        root: null, // Use the viewport as the root
        threshold: 0.0001, // Fire the event when 0.001% of the card is visible
      }
      const observer = new IntersectionObserver(callback, options)

      function callback(entries, observer) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true)
            observer.disconnect() // Disconnect the observer after firing the event
          }
        })
      }

      observer.observe(target)
    }

    if (ref.current) {
      viewportImageObserver(ref.current)
    }
  }, [ref])

  return <img ref={ref} src={isVisible ? imgURL : ''} layout='fill' className={customClass} alt={alt} loading='lazy' />
})
ImageLazy.displayName = 'ImageLazy'
export default ImageLazy
